@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

@layer utilities {
  @variants responsive {
    /* Hide scrollbar for Chrome, Safari, and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge, and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }
}

/* prepare stepper */
.progressbar_prepare,
.progressbar_workflow {
  counter-reset: step;
  margin-left: 0 !important;
}

.progressbar_prepare li {
  list-style: none;
  display: inline-block;
  width: 360px;
  position: relative;
  text-align: center;
  /* cursor: pointer; */
}

.progressbar_prepare li:before,
.progressbar_workflow li:before {
  content: counter(step);
  counter-increment: step;
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 100%;
  display: block;
  text-align: center;
  margin: 0 auto 10px auto;
  background-color: #f0f0f0;
}

.progressbar_prepare li:after,
.progressbar_workflow li:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #d1d1d1;
  top: 15px;
  left: -50%;
  z-index: -1;
}

.progressbar_prepare li:first-child:after,
.progressbar_workflow li:first-child:after {
  content: none;
}

.progressbar_prepare li.active:before,
.progressbar_workflow li.active:before {
  background-color: #0083bf;
  color: white;
}

.progressbar_prepare li.active:after,
.progressbar_workflow li.active:after {
  background-color: #0083bf;
}

.progressbar_prepare li.green:before,
.progressbar_workflow li.green:before {
  background-color: #00da6e;
  color: white;
}

.progressbar_prepare li.green:after,
.progressbar_workflow li.green:after {
  background-color: #00da6e;
}

/* workflow stepper */
.progressbar_workflow li {
  list-style: none;
  display: inline-block;
  width: 190px !important;
  position: relative;
  text-align: center;
  /* cursor: pointer; */
}

.sheet-table {
  border-collapse: collapse;
  border-radius: 5px;
  border-style: hidden; /* hide standard table (collapsed) border */
  box-shadow: 0 0 0 1px #dddee0; /* this draws the table border  */
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  padding-right: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(165, 165, 165);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: grey;
}

.sheet_active {
  background-color: #0083bf;
  color: white;
  border: 1px solid #0083bf;
}

.drop-item {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

input[type="search"]:focus {
  outline: none;
}

.connect-db-container {
  width: 100%;
  /* background-color: brown; */
}

.connect-form {
  width: 100%;
}

.connect-db {
  width: 40%;
}

.form-container {
  width: 100%;
}

.instruction-section {
  width: 65%;
}

.connect-card {
  width: 100%;
}

.preview-table-db {
  width: 100%;
}

.preview-tablename-section {
  width: 73%;
}

.disabled {
  /* Styles for the disabled state */
  opacity: 0.7; /* Example: reduce opacity to make it appear disabled */
  pointer-events: none; /* This will prevent any pointer events from being triggered on the element */
}

.create-select-dropdown {
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) center;
  background-image: url("./assets/downArrow.svg");
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  /* background-color: #F7F7F7 !important; */
  -webkit-box-shadow: 0 0 0 30px #f7f7f7 inset !important;
}

.custom-input::-moz-placeholder {
  color: #707070;
  opacity: 1;
}

.custom-input::-ms-input-placeholder {
  color: #707070;
  opacity: 1;
}

.custom-input::-webkit-input-placeholder {
  color: #707070;
  opacity: 1;
}

@media only screen and (min-width: 1600px) {
  .progressbar_workflow li {
    width: 310px;
  }
  .progressbar_prepare li {
    width: 500px;
  }
}

.back-btn:hover .back-btn-img {
  transform: rotate(-360deg);
  -webkit-transform: rotate(-360deg);
}

.back-btn-img {
  overflow: hidden;
  transition-duration: 0.8s;
  transition-property: transform;
}

.up-arrow {
  display: inline-block;
  position: relative;
  border: 1px solid #777777;
  text-decoration: none;
  border-radius: 2px;
  padding: 3px 7px;
  position: absolute;
  left: 8px;
  top: 45px;
  border-radius: 5px;
  background-color: white;
  z-index: 25;
}

.up-arrow:before {
  content: "";
  display: block;
  position: absolute;
  left: 8px;
  bottom: 100%;
  width: 0;
  height: 0;
  border: 7px solid transparent;
  border-bottom-color: black;
}

.up-arrow:after {
  content: "";
  display: block;
  position: absolute;
  left: 9px;
  bottom: 100%;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-bottom-color: white;
}

.down-arrow {
  display: inline-block;
  position: relative;
  border: 1px solid #777777;
  text-decoration: none;
  border-radius: 2px;
  padding: 3px 7px;
  position: absolute;
  left: 8px;
  bottom: 45px;
  border-radius: 5px;
  background-color: white;
  z-index: 25;
}

.down-arrow:before {
  content: "";
  display: block;
  position: absolute;
  left: 8px;
  top: 100%;
  width: 0;
  height: 0;
  border: 7px solid transparent;
  border-top-color: black;
}

.down-arrow:after {
  content: "";
  display: block;
  position: absolute;
  left: 9px;
  top: 100%;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-top-color: white;
}
